<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form ref="form">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="search.orderCode"
                    :label="$t('productOrder.title')"
                    :name="$t('productOrder.title')"
                    type="text"
                    clearable
                    @keyup.enter="getBankTransactionList"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="search.bankTransactionId"
                    :label="$t('bankTransactionInfo.bankTransactionId')"
                    :name="$t('bankTransactionInfo.bankTransactionId')"
                    type="text"
                    clearable
                    @keyup.enter="getBankTransactionList"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-select
                    :items="bannerTypeList"
                    v-model="search.status"
                    :label="$t('supplierAccessCode.status')"
                    clearable
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getBankTransactionList"
              >
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="filterByOrder"
          :title="$t('bankTransactionInfo.title')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="orderHeaders"
            :items="productOrderList"
            v-model="selected"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props">
              <td>{{ props.item.stt }}</td>
              <!-- <td>{{ props.item.requestId }}</td> -->
              <td
                style="color: #3f51b5!important; cursor: pointer;"
                @click="onShowModalOrderDetail(props.item)">{{ props.item.orderCodeData }}</td>
              <td>{{ formatMoney(props.item.transactionAmount) }} {{ !isEmptyString(props.item.transactionAmount) ? ` ${props.item.currency}`: '' }}</td>
              <td>
                <v-chip
                  :class="props.item.statusBg"
                  :text-color="props.item.statusTextColor"
                >
                  {{ $t(props.item.statusText) }}
                </v-chip>
              </td>
              <td
                style="color: #3f51b5!important; cursor: pointer;"
                @click="onShowModalOrderDetail(props.item)">{{ props.item.bankTransactionId }}</td>
              <td>{{ props.item.accountNo }}</td>
              <!-- <td>{{ props.item.currency }}</td> -->
              <td>{{ props.item.transactionDate }}</td>
              <td>{{ props.item.narrative }}</td>
              <!-- <td>{{ props.item.transactionDetail }}</td> -->
            </template>
          </v-data-table>
          <pagination
            :total="productPaginate.totalPage"
            :current-page="productPaginate.currentPage"
            :row-per-page="productPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loadingBar :is-loading="isLoading" />
    <bankTransactionDetail ref="bankTransactionDetail" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ActionVoucherProductType from 'enum/actionVoucherProductType'
import ProductOrderStatusEnum from 'enum/productOrderStatusEnum'
import dateUtils from 'utils/dateUtils'
import stringUtils from 'utils/stringUtils'
import EntityRoleType from 'enum/entityRoleType'
// import ToastType from 'enum/toastType'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import moment from 'moment'
import TabHeader from 'Components/TabHeader.vue'
import PaymentMethodType from 'enum/paymentMethodType'
import BankTransactionDetail from './BankTransactionDetail'
export default {
  components: {
    BankTransactionDetail,
    TabHeader
  },
  data: () => ({
    orderHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'productOrder.title',
        value: 'orderCodeData'
      },
      {
        sortable: false,
        text: 'bankTransactionInfo.transactionAmount',
        value: 'transactionAmount'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.status',
        value: 'status'
      },
      // {
      //   sortable: false,
      //   text: 'bankTransactionInfo.requestId',
      //   value: 'requestId'
      // },
      {
        sortable: false,
        text: 'bankTransactionInfo.bankTransactionId',
        value: 'bankTransactionId'
      },
      {
        sortable: false,
        text: 'bankTransactionInfo.accountNo',
        value: 'accountNo'
      },
      // {
      //   sortable: false,
      //   text: 'bankTransactionInfo.currency',
      //   value: 'currency'
      // },
      // {
      //   sortable: false,
      //   text: 'bankTransactionInfo.balanceAfterOperation',
      //   value: 'balanceAfterOperation'
      // },
      {
        sortable: false,
        text: 'bankTransactionInfo.transactionDate',
        value: 'transactionDate'
      },
      {
        sortable: false,
        text: 'bankTransactionInfo.narrative',
        value: 'narrative'
      }
      // {
      //   sortable: false,
      //   text: 'bankTransactionInfo.requestTime',
      //   value: 'agent'
      // },
      // {
      //   sortable: false,
      //   text: 'bankTransactionInfo.transactionDetail',
      //   value: 'transactionDetail'
      // }
    ],
    productOrderList: [],
    productPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    category: {
      id: null,
      list: []
    },
    categoryId: null,
    isLoading: false,
    noImg: require('@/assets/noimage.png'),
    code: null,
    actionVoucherProductType: {
      UPDATE_PRODUCT_INFO: ActionVoucherProductType.UPDATE_PRODUCT_INFO,
      PUBLIC_ON_PORTAL: ActionVoucherProductType.PUBLIC_ON_PORTAL
    },
    productOrderStatusList: [
      { text: '---', value: null },
      ...stringUtils.ProductOrderStatusList
    ],
    search: {
      status: null,
      code: null,
      statusList: [ProductOrderStatusEnum.WAIT_FOR_PAY],
      emailOrPhone: null,
      orderCode: null,
      bankTransactionId: null
    },
    confirmActionType: {
      PAYMENT_CONFIRM: 1,
      PAYMENT_CANCEL: 2,
      PAYMENT_CONFIRM_BULK: 3
    },
    productOrderStatusEnum: {
      WAIT_FOR_PAY: ProductOrderStatusEnum.WAIT_FOR_PAY,
      DELIVERED: ProductOrderStatusEnum.DELIVERED,
      CANCEL: ProductOrderStatusEnum.CANCEL
    },
    confirmActionTypeSelected: null,
    orderItemSelected: null,
    filterByOrder: true,
    filterByTabItems: [
      {
        title: 'productOrder.byOrder',
        id: 1
      },
      {
        title: 'productOrder.byVoucher',
        id: 0
      }
    ],
    productInOrderPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    agents: [{ value: null, text: '---' }],
    agentId: null,
    storeChannels: [{ value: null, text: '---' }],
    storeChannelId: null,
    // Start issue expired date to customer
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false, // End issue expired date
    minStartDate: null,
    // Start issue expired date to customer
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false, // End issue expired date
    minEndDate: null,
    selected: [],
    isLoadingPaymentConfirmBulk: false,
    paymentMethod: {
      list: [{ value: null, text: '---' }],
      id: null
    },
    holdingTime: 0,
    orderStatusTabItems: [
      {
        title: 'productOrder.status.noProcess',
        id: 0
      },
      {
        title: 'productOrder.status.processed',
        id: 1
      }
    ],
    objectSettingKey: stringUtils.ObjectSettingKey,
    domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN,
    bannerTypeList: [...stringUtils.BankTransactionStatusList]
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_PRODUCT_ORDERS_LIST_DATA',
      'GET_CURRENT_ACCOUNT',
      'OBJECT_SETTING_DATA'
    ])
  },
  watch: {
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      let settingData = data.filter(element => {
        return element.key === this.objectSettingKey.HOLDING_TIME
      })
      if (settingData.length > 0) {
        this.holdingTime = settingData[0].value
      }
    },
    /**
     * Issue expired date to customer
     */
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    /**
     * Issue expired date to customer
     */
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    }
  },
  created () {
    this.getBankTransactionList()
    this.GET_ENTITIES_OF_USER().then(
      function (res) {
        let data = res.data
        this.agents = [{ value: null, text: '---' }]
        for (let i = 0, size = data.length; i < size; i++) {
          this.agents.push({ value: data[i].id, text: data[i].name })
        }
        this.filterByOrder = true
      }.bind(this)
    )
    this.GET_STORE_CHANNELS_LIST().then(
      function (res) {
        let data = res.data
        this.storeChannels = [{ value: null, text: '---' }]
        for (let i = 0, size = data.length; i < size; i++) {
          let storeChannelObj = {
            value: data[i].id,
            text: data[i].name
          }
          this.storeChannels.push(storeChannelObj)
        }
      }.bind(this)
    )
    let methodList = [PaymentMethodType.CASH_ON_DELIVERY,
      PaymentMethodType.BANK_TRANSFER,
      // PaymentMethodType.MOMO_WALLET,
      // PaymentMethodType.ZALOPAY_WALLET,
      PaymentMethodType.VIET_WALLET]
    this.paymentMethod.list = [{ value: null, text: '---' }]
    for (let i = 0, size = methodList.length; i < size; i++) {
      let obj = {
        value: methodList[i],
        text: functionUtils.paymentMethodTypeName(methodList[i])
      }
      this.paymentMethod.list.push(obj)
    }
    let entityId = sessionStorage.getItem('entityId')
    let filter = {
      params: {
        objectId: entityId,
        objectType: 'Entity'
      }
    }
    this.GET_OBJECT_SETTING(filter)
  },
  methods: {
    isEmptyString: function (value) {
      return functionUtils.isEmptyString(value)
    },
    /**
     * Import excel file to approve order
     */
    onShowModalImportExcelFileToApproveOrder: function () {
      this.$refs.importExcelFileToApproveOrder.onShowModal()
    },
    /**
     * Page product in order change
     */
    onPageProductInOrderChange: function (page) {
      this.productInOrderPaginate.currentPage = page
    },
    /**
     * Filter by
     */
    onFilterBy: function ({ index, item }) {
      this.filterByOrder = item.id === 1
      this.getBankTransactionList()
    },
    // Rename key
    renameKey: function (object, key, newKey) {
      const targetKey = object[key]
      delete object[key]
      object[newKey] = targetKey
      return object
    },
    /**
     * Get discount
     */
    getDiscount: function (type, value) {
      if (value) {
        // eslint-disable-next-line eqeqeq
        if (VoucherProductPromotionType.PRICE == type) {
          return this.formatMoney(value)
          // eslint-disable-next-line eqeqeq
        } else if (VoucherProductPromotionType.PERCENT == type) {
          return value + ' %'
        }
      } else {
        return ''
      }
    },
    /**
     * CHeck accountant role
     */
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    /**
     * Show modal order detail
     */
    onShowModalOrderDetail: function (item) {
      this.$refs.bankTransactionDetail.onShowModal(item)
    },
    /**
     * Get color
     */
    getColor (item) {
      let currentDate = moment().format('YYYY-MM-DD HH:mm')
      // eslint-disable-next-line eqeqeq
      if (item.status == ProductOrderStatusEnum.CANCEL) return 'red'
      // eslint-disable-next-line eqeqeq
      else if (item.status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        // eslint-disable-next-line eqeqeq
        if (this.holdingTime && this.holdingTime != 0 && item.deadlinePayment && currentDate > item.deadlinePayment) {
          return 'pinky'
        }
        return 'orange'
      } else return 'green'
    },
    getTotalPrice: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(items[i].price)
          ? items[i].price : items[i].workpiece.value
        let promotionValue = items[i].product_promotion && items[i].product_promotion.value
        let promotionType = items[i].product_promotion && items[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        total += price * items[i].quantity - promotionPrice
      }
      return total
    },
    /**
     * Get voucher product
     */
    getBankTransactionList: function () {
      this.isLoading = true
      let entityId = sessionStorage.getItem('entityId')
      let filter = {
        params: {
          page: this.productPaginate.currentPage,
          entityId: entityId,
          orderCode: this.search.orderCode,
          bankTransactionId: this.search.bankTransactionId,
          status: this.search.status
        }
      }
      // TODO: recheck
      this.GET_BANK_TRANSFER_PAYMENT_LIST(filter)
        .then(
          function (res) {
            let data = res.data
            // Handle paginate
            this.productPaginate.currentPage = data.page
            this.productPaginate.totalPage = data.num_pages
            this.productPaginate.rowPerPage = data.per_page / 2
            // Handle response data
            let productData = data.results
            this.productOrderList = []
            for (let i = 0, size = productData.length; i < size; i++) {
              let statusData = this.getBankTransactionStatus(productData[i].status)
              let object = {
                stt: i + 1 + (data.page - 1) * data.page_size,
                requestId: productData[i].request_id,
                accountNo: productData[i].account_no,
                balanceAfterOperation: productData[i].balance_after_operation,
                bankTransactionId: productData[i].bank_transaction_id,
                currency: productData[i].currency,
                narrative: productData[i].narrative,
                requestTime: productData[i].request_time,
                transactionAmount: productData[i].transaction_amount,
                transactionDate: !functionUtils.isEmptyString(productData[i].transaction_date)
                  ? dateUtils.formatDate(productData[i].transaction_date, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T, dateUtils.STATIC_FORMAT_DATE_TIME) : null,
                transactionDetail: productData[i].transaction_detail,
                orderCodeData: productData[i].product_order_code,
                orderCodeId: productData[i].product_order,
                status: productData[i].status,
                statusText: statusData.text,
                statusBg: statusData.bgColor,
                statusTextColor: statusData.textColor
              }
              this.productOrderList.push(object)
            }
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return !functionUtils.isEmptyString(price) ? functionUtils.convertFormatNumberToMoney(price) : null
    },
    // Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.productPaginate.currentPage = page
      this.getBankTransactionList()
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    getPromotionPriceOrder: function (voucherTotalPrice, promotionOrderList) {
      let result = 0
      promotionOrderList.forEach(e => {
        if (e.promotion_type === VoucherProductPromotionType.PRICE) {
          result += e.value
        } else if (e.promotion_type === VoucherProductPromotionType.PERCENT) {
          result += voucherTotalPrice * e.value / 100
        }
      })
      return result
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    },
    /**
     * Get status
     */
    getBankTransactionStatus: function (status) {
      return functionUtils.getBankTransactionStatus(status)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_BANK_TRANSFER_PAYMENT_LIST',
      'GET_ENTITIES_OF_USER',
      'GET_STORE_CHANNELS_LIST',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
