<template>
  <v-dialog
    v-model="isShowModal"
    max-width="1000px">
    <v-card>
      <material-card
        :title="`${$t('bankTransactionInfo.modalTitle')} ${code}`"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <!-- Status -->
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="code"
                  :label="$t('bankTransactionInfo.bankTransactionId')"
                  :name="$t('bankTransactionInfo.bankTransactionId')"
                  type="text"
                  disabled
                />
              </v-flex>
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="productOrder"
                  :label="$t('productOrder.title')"
                  :name="$t('productOrder.title')"
                  :append-outer-icon="productOrderData !== null ? 'mdi-details' : ''"
                  type="text"
                  readonly
                  @click:append-outer="productOrderData !== null ? onShowModalOrderDetail(productOrderData) : ''"
                />
              </v-flex>
              <v-flex
                xs12
                md4>
                <div class="v-input v-text-field v-input--is-label-active v-input--is-dirty v-input--is-disabled theme--light">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label
                          aria-hidden="true"
                          class="v-label v-label--active theme--light"
                          style="left: 0px; right: auto; position: absolute;">
                          {{ $t('supplierAccessCode.status') }}
                        </label>
                        <div
                          style="height: 32"
                        >
                          <v-chip
                            :class="statusBg"
                            :text-color="statusTextColor"
                            small
                          >
                            {{ $t(status) }}
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-flex>
              <!-- transactionAmount -->
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="transactionAmount"
                  :label="$t('bankTransactionInfo.transactionAmount')"
                  :name="$t('bankTransactionInfo.transactionAmount')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- accountNo -->
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="accountNo"
                  :label="$t('bankTransactionInfo.accountNo')"
                  :name="$t('bankTransactionInfo.accountNo')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="currency"
                  :label="$t('bankTransactionInfo.currency')"
                  :name="$t('bankTransactionInfo.currency')"
                  type="text"
                  disabled
                />
              </v-flex> -->
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="narrative"
                  :label="$t('bankTransactionInfo.narrative')"
                  :name="$t('bankTransactionInfo.narrative')"
                  type="text"
                  disabled
                />
              </v-flex>
              <v-flex
                xs12
                md4>
                <v-text-field
                  v-model="transactionDate"
                  :label="$t('bankTransactionInfo.transactionDate')"
                  :name="$t('bankTransactionInfo.transactionDate')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- <v-flex
                xs12
                md12>
                <v-text-field
                  v-model="transactionDetail"
                  :label="$t('bankTransactionInfo.transactionDetail')"
                  :name="$t('bankTransactionInfo.transactionDetail')"
                  type="text"
                  disabled
                />
              </v-flex> -->
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
    </v-card>
    <loadingBar :is-loading="isLoading" />
    <orderDetailModal ref="orderDetailModal" />
  </v-dialog>
</template>

<script>
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import OrderDetailModal from './OrderDetailModal.vue'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  components: {
    OrderDetailModal
  },
  data: () => ({
    isLoading: false,
    voucherHeaders: [
      {
        sortable: false,
        text: '#',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'voucherProduct.productTitle',
        value: 'product'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.price',
        value: 'price'
      },
      {
        sortable: false,
        text: 'evoucher.quantity',
        value: 'quantity'
      },
      {
        sortable: false,
        text: 'cart.promotion.discount',
        value: 'promotionValue'
      },
      {
        sortable: false,
        text: 'workpieceDetail.totalPrice',
        value: 'totalPrice',
        class: 'text-right'
      }
    ],
    valid: false,
    isShowModal: false,
    status: null,
    statusBg: null,
    statusTextColor: null,
    voucherProductPromotionType: VoucherProductPromotionType,
    code: null,
    accountNo: null,
    transactionAmount: null,
    currency: null,
    narrative: null,
    transactionDate: null,
    transactionDetail: null,
    productOrder: null,
    productOrderData: null
  }),
  methods: {
    /**
    * Show modal
    */
    onShowModal: function (data) {
      this.isShowModal = true
      this.code = data.bankTransactionId
      this.accountNo = data.accountNo
      this.transactionAmount = this.formatMoney(data.transactionAmount) + ` ${data.currency}`
      this.currency = data.currency
      this.narrative = data.narrative
      this.transactionDate = data.transactionDate
      this.transactionDetail = data.transactionDetail
      let statusData = this.getBankTransactionStatus(data.status)
      this.status = this.$t(statusData.text)
      this.statusBg = statusData.bgColor
      this.statusTextColor = statusData.textColor
      if (data.orderCodeId) {
        this.getProductOrderDetail(data.orderCodeId)
      } else {
        this.productOrderData = null
      }
      this.productOrder = data.orderCodeData
    },
    /**
     * Get status
     */
    getBankTransactionStatus: function (status) {
      return functionUtils.getBankTransactionStatus(status)
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return !functionUtils.isEmptyString(price) ? functionUtils.convertFormatNumberToMoney(price) : null
    },
    /**
     * Get discount
     */
    getDiscount: function (type, value) {
      if (value) {
        // eslint-disable-next-line eqeqeq
        if (VoucherProductPromotionType.PRICE == type) {
          return this.formatMoney(value)
          // eslint-disable-next-line eqeqeq
        } else if (VoucherProductPromotionType.PERCENT == type) {
          return value + ' %'
        }
      } else {
        return ''
      }
    },
    getProductOrderDetail: function (id) {
      this.isLoading = true
      this.PRODUCT_ORDER_DETAIL({ id: id })
        .then(
          function (res) {
            let data = res.data
            let dateCreated = dateUtils.formatBeautyDate(data.date_created)
            let promotionCode = data.promotion_code
            let promotionOrderList = promotionCode.filter(
              x => x.apply_type === VoucherProductApplyType.ORDER)
            let productList = data.items
            let totalPrice = this.getTotalPrice(productList)
            let totalPromotionOrderPrice = this.getPromotionPriceOrder(totalPrice, promotionOrderList)
            let object = {
              orderCode: data.code,
              date: dateCreated,
              deadlinePayment: moment(dateCreated, 'DD/MM/YYYY HH:mm').add(this.holdingTime, 'hours').format('YYYY-MM-DD HH:mm'),
              status: data.status,
              totalPrice: totalPrice - totalPromotionOrderPrice,
              fullname: functionUtils.concatFullname(data.user_order.last_name, data.user_order.first_name),
              voucherList: data.items,
              userOrder: data.user_order,
              id: data.id,
              email: data.user_order.email,
              paidDate: dateUtils.formatBeautyDate(data.date_updated),
              agent: data.entity.name,
              discount: this.getDiscount(
                data.promotion_type,
                data.promotion_value
              ),
              phone: data.user_order.phone,
              paymentMethod: data.payment_method,
              paymentOverdue: false,
              storeChannel: data.store_channel.name,
              approveType: data.approve_type,
              totalPromotionOrderPrice: totalPromotionOrderPrice
            }
            this.productOrderData = object
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getTotalPrice: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(items[i].price)
          ? items[i].price : items[i].workpiece.value
        let promotionValue = items[i].product_promotion && items[i].product_promotion.value
        let promotionType = items[i].product_promotion && items[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        total += price * items[i].quantity - promotionPrice
      }
      return total
    },
    getPromotionPriceOrder: function (voucherTotalPrice, promotionOrderList) {
      let result = 0
      promotionOrderList.forEach(e => {
        if (e.promotion_type === VoucherProductPromotionType.PRICE) {
          result += e.value
        } else if (e.promotion_type === VoucherProductPromotionType.PERCENT) {
          result += voucherTotalPrice * e.value / 100
        }
      })
      return result
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    },
    /**
     * Show modal order detail
     */
    onShowModalOrderDetail: function (item) {
      this.$refs.orderDetailModal.onShowModal(item)
    },
    ...mapActions([
      'PRODUCT_ORDER_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.footer-cell-price {
  width: fit-content;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px
}
/deep/ table.v-table tfoot td {
  padding: 12px 8px;
}
</style>
